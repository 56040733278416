.css-tdxonj-MuiTableHead-root {
  display: table-header-group !important;
}

.ico1 {
  display: block;
  /* width: 40%; */
  height: 3rem;
  margin-top: 1rem;
}

/* table css */

.table-search {
  outline: none;
  height: 25px;
  width: 200px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 0px 10px;
}
.closeButton1{
  background-color: black;
  opacity: 0.7;
  border-radius: 50%;
}

.spacebetween {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.spacearound {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.buttonBarWrapper {
  background: antiquewhite;
  border-radius: 3px;
  height: 31px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #d9d9d9;
  border-right: none;
}
.buttonCoustom {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
}
.label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.monthlyLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label-danger {
  background-color: #e91e63 !important;
}

.tabel_input {
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 0px 10px;
  width: 100px;
  /* color: #66aa18;
  color: #3b6e00;
  color: #244201;
  color: #36c7bb;
  color: #88ff00;
  color:#8704e4; */
}
.tabel_input:focus {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.label-success {
  background-color: #66aa18 !important;
}
.label-late{
  background-color: yellow;
  color: black;
}

.sunday{
  background-color:darkmagenta;
}
.holiday{
  background-color: #448bb4;
}
.textDanger{
  color: #e91e63;
}
.showDataComponent{
  color: #014f7c;
  font-size: 13px;
}

.studentImage{
  display: block;
  width: 100px;
}
.studentImage2{
  display: block;
  width: 90%;
  border-left: 2px solid #1A73E8;
  border-right: 2px solid #1A73E8;
  border-top: 1px solid #1A73E8;
  border-bottom: 1px solid #1A73E8;
  /* border-radius: 10px 0 0 0; */
  /* color: #383838; */
}
.detailsStyle{
  font-size: 14px;
  display: inline-block;
  margin: 1px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid skyblue;
  border-bottom: 1px solid skyblue;
}
.detailsStyleLeft{
  width: 40%;
  text-align: right;
  padding-right: 5px;
  color: #03507c;
}
.detailsStyleRight{
  width: 40%;
  padding-left: 5px;
  color: rgb(46, 46, 46);
}
.evenStyle{
  background-color: rgb(182, 227, 245);
}
.oddStyle{
  background-color: white;
}
/* .inputRadioOfAttendance{
  appearance: none;
  height: 15px;
  width: 15px;
  outline: 1px solid black;
  border-radius: 50%;
} */
/* .exp01:checked{
  background-color: yellow;
  border: 2px solid white;
  outline: 1px solid yellow;
} */
/* className="buttonCoustom" */

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 8px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

